<template>
  <validation-observer ref="form" v-slot="{ invalid }">
    <layout width="540">
      <template slot="header">
        {{ title }}
      </template>

      <template>
        <v-form>
          <validation-provider
            v-slot="{ errors }"
            name="Type name"
            rules="required"
          >
            <v-text-field
              v-model.trim="state.name"
              label="Type name"
              :error-messages="errors"
            />
          </validation-provider>
          <v-textarea
            v-model="state.description"
            label="Description"
            no-resize
            rows="1"
            auto-grow
          />
          <form-switcher
            v-model="state.resolveGeotagsByDefault"
            title="Resolve geotags by default"
          />
          <form-switcher
            v-model="state.resolveMainGeotagByDefault"
            title="Resolve main geotag by default"
          />
        </v-form>
      </template>
      <template slot="footer">
        <v-spacer />
        <v-btn text color="text-primary" @click.stop="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          depressed
          :disabled="invalid"
          :loading="loading"
          @click.stop="submit"
        >
          {{ submitBtnTitle }}
        </v-btn>
      </template>
    </layout>
  </validation-observer>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, onMounted, computed } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { stateService, typeService } from '@/modules/object-types/api';
import { schemasService } from '@/modules/common/api';
import { prepareSchema } from '@/provider/utils';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: 'TypeEdit',
  components: {
    Layout,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    typeId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit, root }) {
    const form = ref(null);
    const state = ref({
      schemaId: '',
      name: '',
      description: '',
      resolveGeotagsByDefault: false,
      resolveMainGeotagByDefault: false
    });

    const type = ref({});

    const edit = computed(() => props.typeId);
    const title = computed(() => (edit.value ? 'Edit type' : 'Add type'));
    const submitBtnTitle = computed(() => (edit.value ? 'Save' : 'Add'));

    const handleSubmit = async () => {
      if (!(await form.value.validate())) return;
      let createdTypeId = props.typeId;
      if (!edit.value) {
        // create new type
        createdTypeId = await typeService.create({
          name: state.value.name,
          description: state.value.description,
          schemaId: state.value.schemaId
        });
        // create default state
        const schemaId = await stateService.fetchBaseStateSchemaId();
        const name = 'Default';
        const createdStateId = await stateService.create({
          name,
          schemaId,
          parentSchemaId: createdTypeId
        });
        const defState = await stateService.fetch(createdStateId);
        const createdType = prepareSchema(
          await typeService.fetch(createdTypeId)
        );
        await schemasService.updateValues(
          [
            {
              id: defState.infoName.id,
              value: name
            },
            {
              id: defState.stateIsDefault.id,
              value: true
            },
            {
              id: createdType.positionResolveGeotags.id,
              value: state.value.resolveGeotagsByDefault
            },
            {
              id: createdType.positionResolveMainGeotag.id,
              value: state.value.resolveMainGeotagByDefault
            }
          ],
          createdStateId
        );
      } else {
        await typeService.update(props.typeId, {
          name: state.value.name,
          description: state.value.description
        });
        await schemasService.updateValues(
          [
            {
              id: type.value.positionResolveGeotags.id,
              value: state.value.resolveGeotagsByDefault
            },
            {
              id: type.value.positionResolveMainGeotag.id,
              value: state.value.resolveMainGeotagByDefault
            }
          ],
          type.value.id
        );
      }

      if (!edit.value) {
        root.$router.push({
          name: 'type_card',
          params: {
            typeId: createdTypeId
          }
        });
      }

      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    onMounted(async () => {
      if (!edit.value) {
        state.value.schemaId = await typeService.fetchBaseTypeSchemaId();
        if (!state.value.schemaId) {
          console.warn(`Can't fetch base schema`);
        }
      } else {
        const fetchedType = await typeService.fetch(props.typeId);
        type.value = prepareSchema(fetchedType);
        state.value = {
          name: type.value.name,
          description: type.value.description || '',
          resolveGeotagsByDefault: type.value.positionResolveGeotags.value,
          resolveMainGeotagByDefault: type.value.positionResolveMainGeotag.value
        };
      }
    });

    return {
      state,
      loading,
      title,
      submitBtnTitle,
      submit,
      edit,
      form
    };
  }
};
</script>
